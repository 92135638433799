<template>
  <div style="height: 100%; width: 100%; ">
    <search-table
      ref="searchTable"
      labelWidth="70px"
      :loading="listLoading"
      :searchFields="searchFields"
      :autoBtnList="autoBtnList"
      :colSize="colSize"
      :total="total"
      :tableData="dataList"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :sortFields="sortFields"
      :checkboxColumn="true"
      :hasPagination="true"
      :showFooter="true"
      :showSetting="false"
      :showColumnSearch="false"
      :showSortable="true"
      :tableSummary="tableSummary"
      :proxyParams="proxyParams"
      :optionColumn="optionColumn"
      :selectTableSummary="selectTableSummary"
      @saveTableField="saveTableField"
      @saveSearchField="saveSearchField"
      @saveSortField="saveSortField"
      @getTableData="queryList"
      @orderManage="orderManage"
      @realLive="realLive"
      @modifyOrgName="modifyOrgName"
      @configManage="configManage"
    >
      <!-- @personalMange="personalMange" -->
    </search-table>
    <el-dialog :visible.sync="dialogVisible" title="修改组织机构名称" width="60%" :close-on-click-modal="false">
      <el-form :model="formData" ref="formData" label-width="150px">
        <el-row :gutter="24">
          <el-col :span="18">
            <el-form-item label="组织机构名称：" prop="organizationName">
              <el-input v-model="formData.organizationName" size="small" class="input-width-16" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitData">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { queryList, updateOrg } from '@/api/project'
import { projectList } from '@/api/stt'
import SearchTable from '@/components/LcSearchTable'
import config from '@/const/project/index.js'
import { formatDate } from '@/utils/time'
import { columnSetting } from '@/api/common'
import column from '@/mixins/column.js'

export default {
  components: {
    SearchTable,
  },
  mixins: [column],

  data: function () {
    return {
      total: 0,
      summary: {},
      dataList: [],
      listLoading: false,
      // 查询条件
      formParams: {},
      baseColumn: [], //接口请求的表格配置
      baseColumns: [],
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 3, //用户设置展示列比例
      bossProList: [],
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      sortFields: [], // 用户自定义的排序设置
      dialogVisible: false,
      formData:{
        id:"",
        organizationName:""
      },
      optionColumn: {
        title: '操作',
        width: 270,
        fixed: 'right',
        btnList: [
          // {
          //   title: '数据统计',
          //   fn: 'dataStt'
          // },
          {
            title: '工单管理',
            fn: 'orderManage'
          },
          {
            title: '工地实况',
            fn: 'realLive'
          },
          // {
          //   title: '人员管理',
          //   fn: 'personalMange'
          // },
          {
            title: '配置管理',
            fn: 'configManage'
          },
          {
            title: '修改组织名',
            fn: 'modifyOrgName'
          }
        ]
      },
      path: '/system/tenant/findByPage', // 更新字段名显示接口路径

      player1:null,
      player2:null,
      liveDialogVisible:false

    }
  },
  created() {
    this.searchFields = config.searchFields(this)
    this.baseColumns = config.tableFields(this)
    this.tableColumns = config.tableFields(this)
  },

  computed: {
    // 操作按钮
    autoBtnList() {
      return config.operateList(this)
    }
  },
  activated() {
    this.getProjectList()
    this.queryList()
  },
  methods: {
    // 获取该老板所有的工地
    getProjectList() {
      projectList().then(res => {
        let arr = [{ value: '', label: '全部项目' }]
        res.data.forEach(item => {
          arr.push({ value: item.name, label: item.name })
        })
        this.bossProList = arr
      })
    },

    // 数据统计
    // dataStt(row) {
    //   this.$router.push({ path: '/project/statistics', query: { id: row.id, name: row.name } })
    // },
    // 工单管理
    orderManage(row) {
      this.$router.push({ path: '/project/order', query: { id: row.id, name: row.name } })
    },

    realLive(row){
      this.$router.push({ path: '/project/video' , query: { id: row.id, name: row.name }})
    },

    modifyOrgName(row) {
      console.info("row:", row)
      this.dialogVisible = true
      this.formData = row
      // this.formData.organizationName = row.organizationName
    },
    // // 人员管理
    // personalMange(row) {
    //   this.$router.push({ path: '/project/personal', query: { id: row.id, name: row.name } })
    // },
    // 配置管理
    configManage(row) {
      this.$router.push({ path: '/project/config', query: { id: row.id, name: row.name } })
    },
    submitData(){
      let params= {
        "id": this.formData.id,
        "organizationName":this.formData.organizationName
      }
      updateOrg(params).then(res => {
        if (res.code === 200) {
          this.dialogVisible = false
          this.$message.success('修改成功')
          this.queryList()
        } else {
          this.$message.error(res.msg)
        }
      })


    },
    // 查询
    async queryList(params) {
      if (params) {
        this.formParams = params
      } else {
        params = this.formParams
      }
      params['role'] = this.$store.getters.userType
      this.listLoading = true
      this.baseColumns = config.baseColumn(this)
      const res = await queryList({ ...params })
      console.log(res)

      if (res.code === 200) {
        this.dataList = res.data.records
        this.total = this.dataList.length
      }
      this.searchFields.forEach(item => {
        if (item.field === 'name') {
          item.selectOptions = this.bossProList
        }
      })
      // console.info(this.dataList)
      // console.info(this.dataList.length)
      this.listLoading = false
    },
    initConfig() {
      this.tableColumns = this.baseColumns.filter(v => v.showField)
      this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
      this.sortFields = this.tableColumns
        .filter(v => v.sortable)
        .reduce((prev, item) => {
          // if(item.sortList) prev = prev.concat(item.sortList)
          prev.push({
            title: `${item.title}升序`,
            field: `${item.field}-asc`
          })
          prev.push({
            title: `${item.title}降序`,
            field: `${item.field}-desc`
          })
          return prev
        }, [])
    },
    // 时间格式化
    dateFormat(data) {
      return formatDate(data)
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          // searchFields: this.searchFields,
          tableFields: fields
        })
        this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      // console.log(fields, colSize)
      this.colSize = colSize
      this.searchFields = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: fields,
          // tableFields: this.tableColumns,
          colSize
        })
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改排序字段配置
    async saveSortField(fields) {
      this.sortFields = fields
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },

    //获取表格头配置
    async getTableField() {},
    //获取搜索字段配置
    async getSearchField() {},
    // async updateProject() {
    //   updateOrg(this.formData.id, formData.organizationName).then(res => {
    //     if (res.code === 200) {
    //       this.dialogVisible = false
    //       this.$message.success('修改成功')
    //       this.queryList()
    //     } else {
    //       this.$message.error(res.msg)
    //     }
    //   })
    // },
  }
}
</script>

<style scoped>
/deep/ .video-container-wrap  {
  position: fixed !important;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

</style>
